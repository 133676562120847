import React from 'react';
import logogithub from './img/25231.png';
import logolinked from './img/Linkedin-Logo.png';
import logoMail from './img/mail icon.png';

export default function Footer() {
  const generateMailtoLink = () => {
    const recipientEmail = 'myportfolio@javiermp.com';
    const mailtoLink = `mailto:${recipientEmail}`;
    return mailtoLink;
  };

  const mailtoLink = generateMailtoLink();

  return (
    
      <div className='footerdiv'>            
        <a href='https://github.com/jav8319' target='_blank' rel='noreferrer'>
          <img className='githublogo'src={logogithub} alt="github logo"></img>
        </a>
        <a href="https://www.linkedin.com/in/javier-muriel-66a34b190/" target='_blank' rel='noreferrer'>
          <img className='linkedlogo' src={logolinked} alt="linkedin logo"></img>
        </a>
        <a href={mailtoLink} target='_blank' rel='noreferrer'>
          <img className='maillogo'src={logoMail} alt="mail logo"></img>
        </a>   
      </div>
  
  );
}

