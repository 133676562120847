
import './style.css';
import {
    ApolloClient,
    InMemoryCache,
     ApolloProvider
} from '@apollo/client';
  import React from "react";
  import PortfolioContainer from "./PortfolioContainer";
 


  
  // Construct our main GraphQL API endpoint
const client = new ApolloClient({
    
    uri: "/graphql",
    cache: new InMemoryCache(),
});
  
  
  
function App () {
    return(
        <ApolloProvider client={client}>
            <PortfolioContainer />
        </ApolloProvider>
    );
    
}
  
export default App;
  
