import { gql } from '@apollo/client';


export const QUERY_RURL= gql`
query ResumeGCUrl {
  resumeGCUrl {
    nameID
    resumeUrl
  }
}
`;




