import React from 'react';
// import imagejmp from '../img/PHOTO.png' ;
// const photoStyle={width: '50px'}


export default function About() {
  
  return (
<div className='aboutdiv'>

  <p >
Hello! I'm a Full-Stack Web Developer with strong math skills and a 
degree in Material Engineering. Having completed my full-stack developer 
certification from the University of Toronto, I have honed my skills in 
JavaScript, Node, SQL, MongoDb, Google Cloud, HTML, and CSS. I've successfully applied these 
skills in my freelance work since 2022, providing businesses with efficient and functional
 web applications.<br></br><br></br> 

 My passion for developing and deploying sophisticated applications is evidenced by several successful projects. 
These include RESTful APIs, database integrations, responsive designs, and version control through GitHub. 
In addition to my technical expertise, I'm a dedicated coder and a continuously curious learner, thriving in 
both independent and team settings. My experience in agile development, along with my proficiency in tools like 
Google Cloud, PayPal integration, database management, React Hooks, and RESTful APIs, enables me to provide efficient 
and collaborative web solutions.<br /><br />


Feel free to browse through my portfolio to witness my journey in leveraging modern 
web development technologies and creating digital solutions that drive businesses 
forward. I look forward to bringing your next web project to life.<br></br><br></br>
  </p>
</div>







  );
}
