import React from 'react';
import { useQuery } from '@apollo/client';
import { QUERY_RURL } from '../utils/queries';

export default function Header({ handlePageChange }) {
  const { data, loading, error } = useQuery(QUERY_RURL);
  const resumeGurl = data?.resumeGCUrl || [];

  if (!error && loading) {
    return (
      <div>
        ...loading
      </div>
    )
  }

  return (
    <div className='headerdiv'>
      <div className='headertitles'>
      <h1>JAVIER MURIEL PALACIOS</h1>
      <h3>WEB DEVELOPER</h3>
      </div>
      <div className='navdiv'>
        <code className='menuburger'>&#9776;</code>
        <ul className='headerul'>
          <li>
            <a 
            
              href="#About me"
              onClick={() => handlePageChange('About me')}
            >
              About me
            </a>
          </li>

          <li>
            <a 
             
              href="#Portfolio"
              onClick={() => handlePageChange('Portfolio')}
            >
              Portfolio
            </a>
          </li>

          <li>
            <a 
             
              href="#Contact"
              onClick={() => handlePageChange('Contact')}
            >
              Contact
            </a>
          </li >

          {/* <li>
            <a 
            
              href={resumeGurl[0]?.resumeUrl || '#'}
              target='_blank' 
              rel='noreferrer'
            >
              Resume
            </a>
          </li> */}
        </ul>
      </div>
    </div>
  );
}
