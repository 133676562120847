import React, { useState } from 'react';
import { TIP_CONTACT } from '../utils/mutations';
import { useMutation } from '@apollo/client';

export default function Contact() {

  const [contactFormData, setContactFormData] = useState({ name: '', email: '', message: '' });

  const [tipContact] = useMutation(TIP_CONTACT);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setContactFormData({ ...contactFormData, [name]: value });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    // Preventing the default behavior of the form submit (which is to refresh the page)
    
    // Alert the user their first and last name, clear the inputs
    
    try {

        alert(`Hello ${contactFormData.name}, I will reach out as soon as possible.Thank You.`);
        await tipContact({
          variables: { ...contactFormData},
        });


    } catch (e) {

      console.error(e);
      alert("you must fill all fields, try again")
    }

    setContactFormData({
      name: '',
      email: '',
      message: '',
    });
  };

  return (
    <div className='contactdiv'>
      <form className="contact-form">
      <div className="form-group">
        <label htmlFor="name">Name: </label>
        <input
          type="text"
          id="name"
          placeholder="Your Name"
          name="name"
          onChange={handleInputChange}
          value={contactFormData.name}
        />
      </div>
      <div className="form-group">
        <label htmlFor="emailInput">Email address: </label>
        <input
          type="email"
          id="emailInput"
          placeholder="name@example.com"
          name="email"
          onChange={handleInputChange}
          value={contactFormData.email}
        />
      </div>
      <div className="form-group">
        <label htmlFor="Textarea">Message: </label>
        <textarea
          id="Textarea"
          rows="5"
          name="message"
          placeholder="Your Message"
          onChange={handleInputChange}
          value={contactFormData.message}
        />
      </div>
      <input type="submit" value="Submit" onClick={handleFormSubmit} className="submit-btn" />
    </form>
    </div>
    
  );
}


