import React, { useState } from 'react';
import Portfolio from './pages/Portfolio';
import Header from './components/Header';
import Footer from './components/Footer';
import About from './pages/About';
import Contact from './pages/Contact';
import './style.css';



export default function PortfolioContainer() {
  const [currentPage, setCurrentPage] = useState('Portfolio');
  
  
  // This method is checking to see what the value of `currentPage` is. Depending on the value of currentPage, we return the corresponding component to render.
  const renderPage = () => {
    if (currentPage === 'About me') {

     
      return <About/>;
    }
    if (currentPage === 'Portfolio') {
      
      return <Portfolio/>;
    }
    
  
    if (currentPage === 'Contact') {
      
      return <Contact/>;
    }
    
  };
  
  const handlePageChange = (page) => setCurrentPage(page);

  return (
    <div>
      <Header handlePageChange={handlePageChange}/>
      <div className='enclosingdiv'>
      <div className='divpagetitle'>
          <div className='rounded-edge-div'><code className='fillertext'>----------------</code></div>
          <div className='rounded-edge-left-div'><h1 className='textpagetitle'>{currentPage}</h1></div>
          <div className='rounded-edge-right-div'><code className='fillertext'>----------------</code></div>
          </div>
        <div className='strap1'><code className='fillertext'>----------------</code></div>
        
  
          
          
          
          
          {renderPage()}
        
      </div>
      <Footer/>


    </div> 
  )
}
